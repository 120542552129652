@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Space Grotesk';
  src: url('./assets/fonts/SpaceGrotesk-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Space Grotesk', sans-serif;
}
